<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.blog')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="article">
            <div class="article-group" v-for="(item,index) in listdata" :key="index">
                <div class="title">
                    <router-link class="lias" :to="{name:'blogDetail',query:{id:item.id}}">
                        {{languageName=='cn'?item.cn_title:''}}
                        {{languageName=='en'?item.en_title:''}}
                        {{languageName=='it'?item.it_title:''}}
                    </router-link>
                </div>
                <div class="imagetext" v-show="item.cover_img!=null">
                    <div class="images"><img :src="item.cover_img" alt=""></div>
                    <div class="pc-text">
                        {{languageName=='cn'?item.cn_description:''}}
                        {{languageName=='en'?item.en_description:''}}
                        {{languageName=='it'?item.it_description:''}}    
                    </div>
                </div>
                <div class="imagetext" v-show="item.cover_img==''">
                    {{languageName=='cn'?item.cn_description:''}}
                    {{languageName=='en'?item.en_description:''}}
                    {{languageName=='it'?item.it_description:''}}
                </div>
                <div class="arttime">
                     <b-row>
                        <b-col class="oths">
                            <span class="other"><span class="shareicon comment"></span>{{$t('message.commentxt')}}（{{item.comment_num}}）</span>
                            <span class="other"><span class="shareicon share"></span>{{$t('message.share')}}（{{item.share_num}}）</span>
                        </b-col>
                        <b-col class="times">{{item.create_time}}</b-col>
                    </b-row>
                </div>
            </div>
            <div class="pc-page" v-show="rows>size">
                <b-pagination :prev-text="$t('message.prev')" :next-text="$t('message.next')" v-model="currentPage" :total-rows="rows" :per-page="size" aria-controls="my-table" @change="handleCurrentChange"></b-pagination>    
            </div>
            <div class="wap-page" v-show="currentPage!=totalPage" @click="getPage">{{$t('message.seemore')}}</div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_lang} from '@/untils/common';
export default {
    name:'blog',
    data() {
        return {
            languageName:'',
            currentPage:1,//当前页
            size:10,//每页显示条数
            rows:0,//总条数
            totalPage:0,//总页数
            listdata:[
                // {
                //     "id": 30,
                //     "title": "英国从2020年5月20日起禁止使用调味和薄荷醇香烟1",
                //     "author": "",
                //     "cover_img": null,
                //     "description": "从2020年5月20日起，欧盟和英国禁止销售含有香料的烟草产品，包括薄荷醇卷烟。该禁和未成年人吸烟的更广泛运动的一部分。英国离开欧盟并不影响禁令的颁布。从2020年5月20日起，欧盟和英国禁止销售含有香料的烟草产品，包括薄荷醇卷烟。该禁和未成年人吸烟的更广泛运动的一部分。英国离开欧盟并不影响禁令的颁布。",
                //     "create_time": "2022-06-12 16:58:57",
                //     "share_num": 1,
                //     "comment_num": 44
                // }
            ]
        }
    },
    methods: {
        //博客列表
        getArticleList(page){
            this.$http.api_article_list({
                typeid:8,// 	一级类型id 	
                page:page,// 	页数 默认1 	
                size:this.size,// 	每页条数 默认10
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.rows = res.data.count;
                            this.totalPage = res.data.totalPage;
                            this.listdata = res.data.list;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // pc分页
        handleCurrentChange(page){
            this.getArticleList(page);
        },
        // wap分页
        getPage(){
            if(this.currentPage<this.totalPage){
                this.currentPage = this.currentPage+1;
            }
            let params = {
                typeid:8,// 	一级类型id 	
                page:this.currentPage,// 	页数 默认1 	
                size:this.size,// 	每页条数 默认10
            }
            this.$http.api_article_list(params).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.rows = res.data.count;
                            this.totalPage = res.data.totalpage;
                            if(this.currentPage>1){
                                this.listdata = this.listdata.concat(res.data.list);
                            }else{
                                this.listdata = res.data.list;
                            }
                            if(this.rows<this.size){
                                return;
                            }
                            if(this.currentPage == this.totalPage){
                                return;
                            }
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        this.getArticleList(1);//博客列表
    }
}
</script>
<style lang="scss" scoped>
// pc端分页样式优化
/deep/.page-item{
    .page-link:focus, 
    .page-link:active:focus, 
    .page-link.active:focus,
    .page-link.focus,
    .page-link:active.focus,
    .page-link.active.focus {
        outline: none;
        box-shadow:none;
    }
    &:nth-child(1){
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '首页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-child(2){
        // .page-link{
        //     color: transparent;
        // }
        .page-link::before{
            // content: v-bind(langageprev);//'上一页';
            padding-left:5px;
            color: #616166;
        }
    }
    &:last-child{
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '尾页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-last-child(2){
        // .page-link{
        //     color: transparent;
        // }
        .page-link::before{
            // content:  v-bind(langagenext);//'下一页';
            padding-left:5px;
            color: #616166;
        }
    }
}
/deep/.page-item:first-child .page-link{
    border-radius: 0;
}
/deep/.page-item:last-child .page-link{
    border-radius: 0;
}
/deep/.page-link{
    border:1px solid #DDE0ED;
    margin: 0 4px;
    padding:6px 10px;
    
    color: #616166;
}
/deep/.page-item.active .page-link{
    color: #007bff;
    background-color: #fff;
    border-color: #fff;
    outline: none;
}
.navtab{
    padding:20px 10px 10px;
    
}
.article{
    padding:10px 20px;
    .article-group{
        padding:10px 0;
        .title{
            cursor: pointer;
            font-size:16px;
            line-height: 34px;
            a{
                color:#2E2E2E;
                font-size:16px;
                text-decoration: none;
            }
        }
        .imagetext{
            display: flex;
            -webkit-box-orient: horizontal;
            font-size:16px;
            color: #7d7d80;
            .images{
                width: 15%;
                border-radius: 10px;
                img{
                    width:100%;
                    height:100px;
                    border-radius: 10px;
                }
            }
            .pc-text{
                width:85%;
                padding-left: 20px;
                line-height: 26px;
                color: #7d7d80;
            }
        }
        .arttime{
            padding:10px 0;
            color:#95959e;
            .oths{
                .other{
                    display: inline-block;
                    margin-right: 50px;
                    color: #a5a5ad;
                    // cursor: pointer;
                    .shareicon{
                        display: inline-block;
                        width: 18px;
                        height:18px;
                        margin:0 5px 0 0px;
                        vertical-align: middle;
                    }
                    .comment{
                        background: url(~@/assets/images/icon_comment.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .share{
                        background: url(~@/assets/images/icon_share.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
            .times{
                text-align: right;
            }
            
        }
        border-bottom:1px solid #eee;
    }
    .pc-page{
        display: flex;
        -webkit-box-orient: horizontal;
        justify-content: center;
        padding:20px 0;
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-page{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-page{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-page{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-page{display: none;}
        .article{
            padding:10px 10px;
            .article-group{
                padding:10px 0;
                .title{
                    cursor: pointer;
                    font-size:16px;
                    line-height: 30px;
                    padding-bottom: 10px;
                    a{
                        color:#2E2E2E;
                        font-size:16px;
                        text-decoration: none;
                    }
                }
                .imagetext{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    font-size:16px;
                    color: #7d7d80;
                    .images{
                        width: 30%;
                        height:80px;
                        border-radius: 10px;
                        img{
                            width:100%;
                            height:80px;
                            border-radius: 10px;
                        }
                    }
                    .pc-text{display: none;}
                    .wap-page{
                        width:70%;
                        padding-left: 20px;
                        line-height: 22px;
                        
                        color: #7d7d80;
                    }
                }
                .arttime{
                    padding:10px 0;
                    color:#95959e;
                    .oths{
                        .other{
                            display: inline-block;
                            margin-right: 20px;
                            color: #a5a5ad;
                            // cursor: pointer;
                            .shareicon{
                                display: inline-block;
                                width: 18px;
                                height:18px;
                                margin:0 5px 0 0px;
                                vertical-align: middle;
                            }
                            .comment{
                                background: url(~@/assets/images/icon_comment.png) no-repeat left top;
                                background-size: 100% 100%;
                            }
                            .share{
                                background: url(~@/assets/images/icon_share.png) no-repeat left top;
                                background-size: 100% 100%;
                            }
                        }
                    }
                    .times{
                        display: none;
                        text-align: right;
                    }
                    
                }
                border-bottom:1px solid #eee;
            }
            .wap-page{
                display: flex;
                -webkit-box-orient: horizontal;
                justify-content: center;
                padding:20px 0;
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-page{display: none;}
        .article{
            padding:10px 10px;
            .article-group{
                padding:10px 0;
                .title{
                    cursor: pointer;
                    font-size:16px;
                    line-height: 30px;
                    padding-bottom: 10px;
                    a{
                        color:#2E2E2E;
                        font-size:16px;
                        text-decoration: none;
                    }
                }
                .imagetext{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    font-size:16px;
                    color: #7d7d80;
                    .images{
                        width: 30%;
                        height:80px;
                        border-radius: 10px;
                        img{
                            width:100%;
                            height:80px;
                            border-radius: 10px;
                        }
                    }
                    .pc-text{display: none;}
                    .wap-page{
                        width:70%;
                        padding-left: 20px;
                        line-height: 22px;
                        
                        color: #7d7d80;
                    }
                }
                .arttime{
                    padding:10px 0;
                    color:#95959e;
                    .oths{
                        .other{
                            display: inline-block;
                            margin-right: 20px;
                            color: #a5a5ad;
                            // cursor: pointer;
                            .shareicon{
                                display: inline-block;
                                width: 18px;
                                height:18px;
                                margin:0 5px 0 0px;
                                vertical-align: middle;
                            }
                            .comment{
                                background: url(~@/assets/images/icon_comment.png) no-repeat left top;
                                background-size: 100% 100%;
                            }
                            .share{
                                background: url(~@/assets/images/icon_share.png) no-repeat left top;
                                background-size: 100% 100%;
                            }
                        }
                    }
                    .times{
                        display: none;
                        text-align: right;
                    }
                    
                }
                border-bottom:1px solid #eee;
            }
            .wap-page{
                display: flex;
                -webkit-box-orient: horizontal;
                justify-content: center;
                padding:20px 0;
            }
        }
    }
}
</style>